<template>
    <path d="m320 0c-106 0-192 35.8-192 80v52.6c-74.5 11-128 40.6-128 75.4v224c0 44.2 86 80 192 80s192-35.8 192-80v-52.7c74.5-11 128-40.5 128-75.3v-224c0-44.2-86-80-192-80zm16 428.3c-10 11.7-60.4 35.7-144 35.7s-134-24-144-35.7v-39.5c35.2 16.6 86.6 27.2 144 27.2s108.8-10.6 144-27.2v39.5zm0-96c-10 11.7-60.4 35.7-144 35.7s-134-24-144-35.7v-44.9c35.2 20 86.6 32.6 144 32.6s108.8-12.7 144-32.6v44.9zm-144-60.3c-79.5 0-144-21.5-144-48s64.5-48 144-48 144 21.5 144 48-64.5 48-144 48zm272 28.3c-7.1 8.3-34.9 22.6-80 30.4v-47.7c31-4.6 58.7-12.1 80-22.2v39.5zm0-96c-7.1 8.3-34.9 22.6-80 30.4v-26.7c0-7.2-2.5-14.2-6.8-20.9 33.8-5.3 64-14.8 86.8-27.8v45zm-144-60.3c-5 0-9.8-0.3-14.7-0.5-26-7.9-56.8-13.2-90.4-14.9-23.9-8.6-38.9-20-38.9-32.6 0-26.5 64.5-48 144-48s144 21.5 144 48-64.5 48-144 48z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 512, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
